export type Region =
  | "Drenthe"
  | "Flevoland"
  | "Friesland"
  | "Gelderland"
  | "Groningen"
  | "Limburg"
  | "Noord-Brabant"
  | "Noord-Holland"
  | "Overijssel"
  | "Utrecht"
  | "Zeeland"
  | "Zuid-Holland"

export const regions: Region[] = [
  "Drenthe",
  "Flevoland",
  "Friesland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Noord-Brabant",
  "Noord-Holland",
  "Overijssel",
  "Utrecht",
  "Zeeland",
  "Zuid-Holland",
]

export type RegionIncNed =
  | "Drenthe"
  | "Flevoland"
  | "Friesland"
  | "Gelderland"
  | "Groningen"
  | "Limburg"
  | "Noord-Brabant"
  | "Noord-Holland"
  | "Overijssel"
  | "Utrecht"
  | "Zeeland"
  | "Zuid-Holland"
  | "Nederland"

export const regionsIncNed: RegionIncNed[] = [
  "Drenthe",
  "Flevoland",
  "Friesland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Noord-Brabant",
  "Noord-Holland",
  "Overijssel",
  "Utrecht",
  "Zeeland",
  "Zuid-Holland",
  "Nederland",
]

export type EventType = "Evenement" | "Try out" | "Workshop" | "Coaching" | "Masterclass"

export const eventTypes: EventType[] = ["Evenement", "Try out", "Workshop", "Coaching", "Masterclass"]

export enum PostType {
  Looking = "LOOKING",
  Offering = "OFFERING",
}

export const postTypes: { [key: string]: string } = {
  Aangeboden: "OFFERING",
  Gezocht: "LOOKING",
}

export type Category = "Dans" | "DJ" | "Expo" | "Fashion" | "Film" | "Muziek" | "Taal" | "Theater"

export const categories: Category[] = ["Dans", "DJ", "Expo", "Fashion", "Film", "Muziek", "Taal", "Theater"]

export type EventDate = "Future" | "Past"
export const dates: EventDate[] = ["Future", "Past"]

export const categoryFilterOptions: { [key: string]: string } = {
  "Content Creator": "Content Creator",
  Dans: "Dans",
  DJ: "DJ",
  Expo: "Expo",
  Fashion: "Fashion",
  Film: "Film",
  Muziek: "Muziek",
  Taal: "Taal",
  Theater: "Theater",
}

export const categoryMultiOptions: { [key: string]: string } = {
  // "Content Creator": "Content Creator",
  Dans: "Dans",
  DJ: "DJ",
  Expo: "Expo",
  Fashion: "Fashion",
  Film: "Film",
  Muziek: "Muziek",
  Taal: "Taal",
  Theater: "Theater",
}

export const competitionYears: string[] = ["2021", "2022", "2023", "2024", "2025", "2026"]

export enum ActStatus {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  WaitingList = "WAITING_LIST",
  RejectedPreRound = "REJECTED_PRE_ROUND",
}

export const actsStatus: { [key: string]: string } = {
  Goedgekeurd: "ACCEPTED",
  Afvaller: "REJECTED",
  Wachtlijst: "WAITING_LIST",
  "Afvaller Selectieronde": "REJECTED_PRE_ROUND",
}
